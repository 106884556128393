import React from 'react'
import './About.css';
import headshot from '../images/headshot.png';
import {Container, Row, Col, Image} from 'react-bootstrap';

export default function About() {
  return (
    <Container >
        <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} className='aboutImgBox'>
            <Image fluid className='aboutImg rounded-20' src={headshot} alt="Cassie Kaminski headshot" />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} className='aboutTextBox'>
           <p> Hello, My name is Cassie Kaminski. I am a full stack developer,
            leveraging 8 years of teaching Math/Robotics.  I found a love for
            coding while teaching Robotics and I decided to learn to code on my own.
            I used a combination of tools to learn, including the Odin Project, Code Academy,
            Udemy, PluralSight, Youtube and Google!<br /><br />

            After one year of studying, I have now become a full stack developer and
            I am able to build full stack apps using JavaScript, React, Node.js, HTML,
            CSS and MongoDB (I use the MERN stack).<br /><br />

            In my free time, you can find me exercising on the Peloton or Tonal, taking 
            my dogs on a walk, hiking, or spending time with my family.
            </p>
        </Col>
        </Row>
    </Container>
  )
}
