import {Routes, Route, useNavigate} from 'react-router-dom';
import { useRef } from 'react';
import {HeaderPortfolio} from './components/Header';
import Footer from './components/Footer';
import Home  from './pages/Home';
import About from './pages/About';
import ProjectsPage from './pages/Projects';
import { Grommet } from 'grommet';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


//like this website https://stratisbakas.com/

const theme = {
  global: {
    
    colors:{
      brand: 'light-2'
    } 
  },
};

function App() {

  
   // run this function from an event handler or an effect to execute scroll 

  return (
    <Grommet theme={theme} >
    <div className="vw-100">
    <HeaderPortfolio />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<ProjectsPage />} />
    </Routes>
    <Footer />
    </div>
    </Grommet>
  );
}

export default App;
