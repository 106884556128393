import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import "./header.css";
import { Header } from "grommet";

export function HeaderPortfolio(props) {
  const [showNav, setShowNav] = useState(false);

  const navButtonHandleChange = (e) => {
    if (showNav) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  //close the mobile nav if a link is clicked on
  const changeShowNavMobile = () =>{
    if(window.matchMedia("(max-width: 1300px)").matches){
        setShowNav(false);
    }
  }

  useEffect(() => {
    
        if(window.matchMedia("(max-width: 1300px)").matches){
            let header = document.getElementById('header');

            if(showNav){
                header.style.position = 'fixed'
                header.style.height = "100%";
            }else{
                header.style.position = 'relative'
                header.style.height = "180px"
            }
        }

  }, [showNav])

  return (
    <>
      <div id="header" className="flexHeader">
        <div className="flexHeaderBoxes">
          <Logo />
        </div>
        <div
          id="navBox"
          className={
            showNav
              ? " flexHeaderBoxes HeaderLinkBox"
              : "flexHeaderBoxes HeaderLinkBox hideRegNav showNav"
          }
        >
          <HeaderElements changeShowNavMobile={changeShowNavMobile} />
        </div>
        <div className="flexHeaderBoxes navFlexBox">
          <button className="menuBtn" onClick={navButtonHandleChange}>
            {showNav ? <NavButtonOpen /> : <NavButton />}
          </button>
        </div>
      </div>
    </>
  );
}

let links = [
  {
    text: "Welcome",
    url: "/",
    onSite: true,
  },
  {
    text: "About Me",
    url: "/about",
    onSite: true,
  },
  {
    text: "Projects",
    url: "/projects",
    id: "projects",
    onSite: true,
  },
  {
    text: "LinkedIn",
    url: "http://www.linkedin.com/in/cassierice",
    target: "_blank",
    onSite: false,
  },
  {
    text: "Github",
    url: "http://www.github.com/ckaminski5000",
    target: "_blank",
    onSite: false,
  },
];

function HeaderElements(props) {
  return (
    <>
      {links.map((link, index) => {
        let item;

        if (link.onSite) {
          item = (
            <Link className="HeaderLinkText" to={{pathname: link.url}} onClick={props.changeShowNavMobile}>
              {link.text}
            </Link>
          );
        } else {
          item = (
            <a
              id={link.id}
              className="HeaderLinkText"
              href={link.url}
              target={link.target}
            >
              {link.text}
            </a>
          );
        }

        return (
          <div key={index} className="HeaderLinks">
            {item}
          </div>
        );
      })}
    </>
  );
}

function Logo() {
  return (
    <div className="logo">
      <svg
        id="ckLogo"
        width="146"
        height="115"
        viewBox="0 0 146 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.0357 83.0189L48.0477 83.0131L48.0596 83.0071C50.0011 82.029 51.8034 80.8916 53.4642 79.5943L55.4928 81.5818C53.6814 82.9929 51.716 84.2519 49.5943 85.3581C47.0554 86.6343 44.2511 87.5642 41.1729 88.14C36.7089 88.975 32.3784 88.9121 28.168 87.9625C23.9336 87.0075 20.1074 85.3278 16.6761 82.9256L16.6634 82.9167L16.6505 82.9081C13.1704 80.5771 10.2213 77.6168 7.80024 74.0139C5.3929 70.4314 3.76897 66.4164 2.93396 61.9524C2.09894 57.4884 2.16181 53.1579 3.11143 48.9475L3.11145 48.9475L3.11323 48.9394C4.05813 44.6395 5.72858 40.7488 8.12186 37.2541C10.4648 33.833 13.4336 30.9104 17.0424 28.4854L17.0505 28.48L17.0584 28.4745C20.6261 26.0097 24.6296 24.3584 29.0862 23.5247C32.1684 22.9482 35.1227 22.802 37.9545 23.0756L37.9632 23.0764C40.5983 23.3155 43.1589 23.8472 45.6475 24.6715L47.1882 32.9083L44.4753 33.4158L43.4625 28.0017L43.2894 27.0763L42.3807 26.8298C40.2905 26.2629 38.1743 25.9382 36.0342 25.8569C33.8715 25.7123 31.7237 25.8392 29.5937 26.2377C25.559 26.9924 21.8877 28.5015 18.5959 30.7676C15.3771 32.9442 12.7215 35.6359 10.6414 38.8378C8.43779 42.0598 6.91755 45.6313 6.07923 49.5392C5.22345 53.4104 5.17684 57.3656 5.92999 61.392C6.68347 65.4201 8.16271 69.1203 10.3684 72.4796L10.3684 72.4796L10.3739 72.4879C12.5582 75.7673 15.2529 78.4568 18.4553 80.5404C21.5445 82.7655 24.9885 84.3413 28.7749 85.2691L28.7864 85.2719L28.7979 85.2745C32.6141 86.146 36.5432 86.1981 40.5711 85.4447C43.2117 84.9508 45.7016 84.1432 48.0357 83.0189Z"
          stroke="#5D7BE7"
          stroke-width="3"
        />
        <path
          d="M57 3.01463H54V6.01463V108.159V111.159H57H67.368C69.1544 111.159 70.9748 110.85 72.4728 109.865C74.1063 108.79 74.9901 107.154 75.2106 105.316L75.232 105.138V104.959V68.5459C79.0269 72.692 90.3412 85.0736 109.185 105.702L109.198 105.716L109.211 105.73C112.347 109.075 116.418 110.854 121.197 111.153L121.29 111.159H121.384H131.368H137.824L133.661 106.224L88.4648 52.6538L133.102 8.13886L138.241 3.01463H130.984H123.186C121.506 2.88679 119.993 3.59489 118.811 4.39083C117.53 5.25421 116.227 6.49946 114.907 8.00228L114.902 8.00711C113.705 9.3758 100.77 24.2673 76.1117 52.6673C75.7818 52.9561 75.4889 53.1894 75.232 53.3754V11.8455C75.5318 9.38894 74.8806 7.00358 72.8363 5.3064C70.9615 3.74997 68.3488 3.15825 65.6003 3.0185L65.5242 3.01463H65.448H57Z"
          stroke="#7524A7"
          stroke-width="6"
        />
      </svg>
    </div>
  );
}

function X() {
  return (
    <div className="mobileNavX">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  );
}

function NavButton() {
  return (
    <div className="navButton">
      <svg
        width="45"
        height="32"
        viewBox="0 0 182 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect c id="top" width="182" height="32" fill="#9DD6F5" />
        <rect id="bottom" y="96" width="182" height="32" fill="#9DD6F5" />
        <rect id="middle" y="48" width="182" height="32" fill="#9DD6F5" />
      </svg>
    </div>
  );
}

function NavButtonOpen() {
  return (
    <div className="navButton">
      <svg
        width="60"
        height="52"
        viewBox="0 0 220 206"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="38" width="182" height="32" fill="#9DD6F5" />
        <rect x="28" y="174" width="182" height="32" fill="#9DD6F5" />
        <rect y="87" width="182" height="32" fill="#9DD6F5" />
      </svg>
    </div>
  );
}

//Header for mobile devices
function HeaderMobile() {
  return (
    <div id="mobileNavBox">
      <div className="mobileNavRow">
        <div className="mobileNavCol">
          <X />
        </div>
      </div>
    </div>
  );
}
