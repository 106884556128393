import React from 'react';
import './footer.css';
import {Footer, Text, Anchor} from 'grommet';
import linkedIn from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import github from '../images/github.svg';

export default function FooterPortfolio() {

  const socialAccounts = [
                  {
                    imgUrl: linkedIn,
                    url: 'http://www.linkedin.com/in/cassierice'
                  },
                  {
                    imgUrl: github,
                    url: 'http://www.github.com/ckaminski5000'
                  },
                  {
                    imgUrl: twitter,
                    url: 'https://twitter.com/CurlyCassieDev' 
                  }

  ]

  return (
    <Footer background="brand" pad="medium">
      <Text>&copy; Copyright CKaminski Labs</Text>
      
      {socialAccounts.map((account, index) => {
          
          return(
          <a key={index} className='socialMediaLinks' href={account.url} target="_blank">
            <img className='socialMediaImgs' src={account.imgUrl} />
          </a>
          );
      })}

    </Footer>
  )
}
