import React from 'react'
import {Projects} from './Home';
import {Container, Row, Col, Image} from 'react-bootstrap';
import './Projects.css';

export default function ProjectsPage() {
  return (
    <>
    <Container>
        <Row>
            <Col><h1 className='projectTitle'>Projects</h1></Col>
        </Row>
        
        <Projects />
         
    </Container>
    </>
  )
}
