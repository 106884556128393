import React from 'react'
import './Home.css';
import {Container, Row, Col, Image} from 'react-bootstrap';
import arrow from '../images/arrow.svg';
import ShoppingCartPic from '../images/ShoppingCartApp.png';
import SurveyMakerAppPic from '../images/surveyMakerScreenshot.png';
import WeatherAppPic from '../images/WeatherAppScreenshot.png';
import FruitMemoryGamePic from '../images/FruitMemoryGameScreenshot.png';

export default function Home(props) {
  return (
    <Container className='homebg'>
    <Row>
      <Col  xs ={12} sm={12} md={12} >
          <p className='title'>Hello, I'm <span className="titleOne" >Cassie Kaminski</span>
        <br /> I'm a &nbsp;
        <span className='titleTwo'> Full Stack Developer.</span>  I like to code in
        <br />
        <span className='titleThree'>JavaScript | React | Node.js | MongoDB | HTML | CSS</span>
        <br /><br /> 
        I like to make websites and web applications that are mobile responsive.  <br />
        Please see my most recent projects below: 

        
        </p>
        <div className='arrowBox'>    <img className="arrowImg" src={arrow} /></div>

      </Col>

    </Row>
    
    <Projects />
    
   
    </Container>
  )
}



export function Projects(){


    const projectData = [
                  {
                    imgUrl: SurveyMakerAppPic,
                    title: 'Full Stack Survey Application',
                    description: 'This is a survey full stack application, similar to Google Docs.  It was made with React, Node.js, MongoDB, Auth0, and Bootstrap.  With this application, you can create an account, create surveys, distribute them and see your results in real time.',
                    login:'Test Login: username: test@test123.com  password: password+2',
                    githubUrl: 'https://github.com/ckaminski5000/SurveyMaker',
                    url:'https://www.surveymaker.app'
                  },
                  {
                    imgUrl: ShoppingCartPic,
                    title: 'React Shopping Cart Application',
                    description: 'This is a basic shopping cart application that was built using React.  In this app, you can add and remove items to a cart and they show up in the cart.',
                    githubUrl: 'https://github.com/ckaminski5000/shoppingCart',
                    url:'https://shopping-cart-5000.herokuapp.com/'
                  },
                  {
                    imgUrl: WeatherAppPic,
                    title: 'Vanilla JavaScript Weather Application',
                    description: 'This is an app written in Vanilla Javascript that displays the current weather and the 5 day forecast for any city around the world. This app gets the weather data from the OpenWeather API.',
                    githubUrl: 'https://github.com/ckaminski5000/WeatherApp',
                    url:'https://weather-app-flax-psi.vercel.app/'
                  },
                  {
                    imgUrl: FruitMemoryGamePic,
                    title: 'React Memory Game',
                    description: 'This is a Memory Game made using React.  To play, click a different fruit each round and earn a point.  If you click the same fruit twice in different rounds, you lose!',
                    githubUrl: 'https://github.com/ckaminski5000/MemoryGame',
                    url:'https://memory-game-coral-eight.vercel.app/'
                  }
    ];

    return (

        projectData.map((project, index) => {

          return (
           <Row key={index} className='projectsRow'>
            <Col xs ={12} sm={12} md={12} lg={6} className='projectTextBox'>
              <h1>{project.title}</h1>
              
              <p>{project.description}
              <br /><br />
              <a href={project.url} >Live Website</a>

              <p style={{color: 'darkgreen'}}>{project.login && project.login}</p>
              <a href={project.githubUrl} >Github Repository Link</a>
              
              </p>

            </Col>
            <Col  xs ={12} sm={12} md={12} lg={6} className='projectTextBox'>
            <a href={project.url}>
            <Image
                  className='projectImg'
                  alt={project.title}
                  src={project.imgUrl}
                  fluid
                />
              </a>

            </Col>

          </Row>
          );

        })

    );

}